
import { defineComponent, onMounted, ref, Ref } from "vue";
import DataTable from "primevue/datatable";
import InputText from 'primevue/inputtext'
import Column from "primevue/column";
import { getAllPageData } from "@/api";
import { PaperData } from "@/models/PaperData";
import { Item } from "@/models/Item";
import { useRouter } from "vue-router";
import { CompanyMinimal } from "@/models/CompanyMinimal";

export default defineComponent({
  name: "PaperList",
  components: {
    DataTable,
    Column,
    InputText,
  },
  setup() {
    const router = useRouter();
    const papers: Ref<PaperData[]> = ref([]);
    const filter = ref({})

    onMounted(async () => {
      papers.value = await getAllPageData();
      papers.value.sort((a, b) => {
        if ((a.date as Date) == (b.date as Date)) {
          return (a.company as CompanyMinimal).companyName >
            (b.company as CompanyMinimal).companyName
            ? 1
            : -1;
        } else {
          return (a.date as Date) > (b.date as Date) ? 1 : -1;
        }
      });
      papers.value = papers.value.map((x) => {
        const d = new Date(x.date as Date);
        const l: any = x.data as any;
        const total: number = l
          .reduce((a: number, b: Item) => {
            return a + b.VaVcTVA;
          }, 0)
          .toFixed(2);

        return {
          ...x,
          dateString: `${d.getFullYear()}.${d.getMonth() + 1}.${d.getDate()}`,
          total,
        };
      });
      console.log(papers.value);
      console.log(papers.value);
    });

    const onRowClick = (event: any) => {
      console.log(event.data);
      router.push({
        name: "Paper",
        query: {
          pageId: event.data.pageId,
        },
      });
    };

    return { papers, onRowClick, filter };
  },
});
const normalDate = (date: Date) => {
  const d = new Date(date);
  return `${d.getFullYear}-${d.getMonth}-${d.getDate}`;
};
